import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import KWNumber from '../numbers/number';
import './numbersection.css';

const KWNumberSection = () => {
  const formatNumber = (number) => {
    return number.toLocaleString('en-US');
  };

  return (
    <Container className='number-section-container mb-5'>
      <Row className='number-section-row'>
        <Col sm={12} lg={3} className='number-section-col'>
          <KWNumber number={formatNumber(2500)} name='Properties Managed' />
        </Col>
        <Col sm={12} lg={3} className='number-section-col'>
          <KWNumber number={formatNumber(20)} name='Cities Managed' />
        </Col>
        <Col sm={12} lg={3} className='number-section-col'>
          <KWNumber number={formatNumber(20000)} name='Properties Rented' />
        </Col>
        <Col sm={12} lg={3} className='number-section-col'>
          <KWNumber number={formatNumber(1000)} name='Clients' />
        </Col>
      </Row>
    </Container>
  );
};

export default KWNumberSection;