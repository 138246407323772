import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './intro.css';

const KWIntro = () => {
  return (
    <Container>
      <Row>
        {/* Services Section */}
        <Col sm={12} lg={6} className="video-item services-section">
          <h1>
            OUR <span className="blue">SERVICES</span>
          </h1>
          <h4 className="text-uppercase">Quality. Trust. Simplicity.</h4>
          <p>
          We specialize in property management, delivering efficient day-to-day operations, prompt issue resolution, and cutting-edge technology to streamline every process. Contact us today to discover how we can help!
          </p>
        </Col>

        {/* Tenant Section */}
        <Col sm={12} lg={6} className="video-item tenant-section">
          <h1>
            Become our <span className="blue fw-light">Tenant</span>
          </h1>
          <p>
            Joining our community is simple and rewarding. Start by exploring our diverse selection of well-maintained properties, designed to suit a variety of preferences and lifestyles. Once you find your ideal space, our intuitive online platform makes submitting your application quick and easy.
          </p>
          <p>
            We prioritize your comfort and satisfaction through competitive rental rates, transparent lease agreements, and prompt, dependable maintenance services. Our tenant-focused approach extends beyond housing—we’re committed to fostering a welcoming, secure, and inclusive community.
          </p>
          <p>
            Choose us as your property manager and experience the difference of leasing with a team dedicated to your well-being and convenience. Welcome home!
          </p>
          <a href="/BecomeATenent" className="btn btn-primary">
            Become A Tenant
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default KWIntro;
