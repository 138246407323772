import React from 'react'
import './team.css'
import KWNavbar from '../../components/navbar/navbar'
import KWFooter from '../../components/footer/footer'
import { Container } from 'react-bootstrap'
import { team } from '../../data/team'
import { Teamcard } from '../../components/teamcard/teamcard'
import './team.css'

export const Team = () => {
  return (
    <>
    <KWNavbar/>
        <Container className='mt-5 pt-5'>
            <h1>Meet the <span className="blue">TEAM</span></h1>
            <h4 className='team-desc mb-5'>The strength of K-W Property Management Corp., is in our team and how we support each other to get the job done for our clients. We take great pride in our ability to work together to give our clients the most positive experience that working with a Property Management Company should be.</h4>
            {team.map((member)=> {
                return(
                    <Teamcard key={member.id} name={member.name} designation={member.designation} description={member.description} img={member.img}></Teamcard>
                )
            })}
        </Container>
    <KWFooter/>
    </>
  )
}
