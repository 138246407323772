import React, { useEffect, useState } from 'react';
import KWNavbar from '../../components/navbar/navbar';
import KWFooter from '../../components/footer/footer';
import './ServiceRequest.css';

import swal from 'sweetalert';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import EmailVerificationSection from './EmailVerificationSection';
import PropertyDetailsSection from './PropertyDetailsSection';
import ServiceRequestForm from './ServiceRequestForm';
import TermsAndConditionsSection from './TermsAndConditionsSection';

const ServiceRequestNew = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isTenantExist, setIsTenantExist] = useState(null);
    const [email, setEmail] = useState("");
    const [serviceCategory, setServiceCategory] = useState({ category: [] });
    const [subCategory, setsubCategory] = useState([]);
    const [countFile, setFileCount] = useState([]);
    const [serviceReqData, setServiceReqData] = useState({
        PropertyId: '',
        CategoryId: '',
        Category: '',
        SubCategoryId: '',
        SubCategory: '',
        RequestSummary: '',
        UnitId: '',
        TenantId: '',
        Tenant: '',
        Priority: '',
        propertyAddress: '',
        source:''

    });
    const [isTermsAndConditionsChecked, setTermsAndConditionsChecked] = useState(false);
    const [isForThisServiceCallChecked, setisForThisServiceCallChecked] = useState(true);
    const [isIacknowledgeAndChecked, setIacknowledgeAndChecked] = useState(false);
    const [captchaVerified, setCaptchaVerified] = useState(false);

    useEffect(() => {
        if (isTenantExist != null) {
            setServiceReqData(prev => ({
                ...prev,
                PropertyId: Number(isTenantExist.PropertyId),
                UnitId: Number(isTenantExist.UnitId),
                TenantId: Number(isTenantExist.TenantId),
                Tenant: (isTenantExist.LastName ? isTenantExist.LastName : "") + ", " + (isTenantExist.FirstName ? isTenantExist.FirstName : ""),
                PropertyAddress: isTenantExist.PropertyAddress ? isTenantExist.PropertyAddress : "",
                source: isTenantExist.source
            }));
        }
    }, [isTenantExist]);

    const verifyTenantEmail = async () => {
        setIsLoading(true);
        setIsTenantExist(null);
        if (email.trim() === "") {
            swal("", "Please provide a valid email id", "warning");
            setIsLoading(false);
            return;
        }

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/v1/tenant/validate`, { email });
            if (res.status === 200) {
                const categoryRes = await axios.get(`${process.env.REACT_APP_API_URL}/v1/svcRequest/categories`);
                setServiceCategory({ category: categoryRes.data.data });
                setIsTenantExist(res.data.data);
            } else {
                swal("No tenant exists with the email id " + email);
            }
        } catch (err) {
            swal("No tenant exists with the email id " + email);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDocumentUploads = (e) => {
        const maxFiles = 5;
        const maxFileSize = 1 * 1024 * 1024; // 1MB in bytes
        const allowedTypes = ['image/gif', 'image/png', 'image/jpeg', 'application/pdf'];
        const files = Array.from(e.target.files);


        if (files.some(file => !allowedTypes.includes(file.type))) {
            swal('', 'file type unsupported formats and were not uploaded.', 'info');
            return false
        }
        if (files.some(file => file.size > maxFileSize)) {
            swal('', 'file size exceed 1MB and were not uploaded.', 'info');
            return false
        }

        if (countFile.length < maxFiles) {
            setFileCount(prev => [...prev, ...e.target.files]);
        } else {
            swal('', 'Sorry, you can\'t upload more than 5 files', 'info');
        }
    };

    const removeFile = (index) => {
        const newFiles = [...countFile];
        newFiles.splice(index, 1);
        setFileCount(newFiles);
    };

    const handleCaptcha = (value) => {
        setCaptchaVerified(!!value);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length + countFile.length > 5) {
            swal('', 'Sorry, you can\'t upload more than 5 files', 'info');
            return;
        }
        setFileCount((prev) => [...prev, ...files]);
    };

    const submitWorkOrderForm = () => {
        // Validate Service Category
        if (!serviceReqData.CategoryId) {
            swal("", "Please select a service category.", "warning");
            return;
        }
        // Validate Request Summary (must be between 10 and 255 characters)
        if (!serviceReqData.RequestSummary) {
            swal("", "Please provide a description of the service required.", "warning");
            return;
        } else if (serviceReqData.RequestSummary.length < 10) {
            swal("", "Description should be at least 10 characters long.", "warning");
            return;
        } else if (serviceReqData.RequestSummary.length > 250) {
            swal("", "Description should not exceed 250 characters.", "warning");
            return;
        }
        // Validate Priority
        if (!serviceReqData.Priority) {
            swal("", "Please select a priority level.", "warning");
            return;
        }
        if (!isForThisServiceCallChecked) {
            if (!isIacknowledgeAndChecked) {
                swal("", "Please checked the acknowledgement .", "warning");
                return;
            }
        }
        if (!isTermsAndConditionsChecked) {
            swal("", "Please accept the terms and conditions.", "warning");
            return;
        }
        if (!captchaVerified) {
            swal("", "Please verify you are not a robot.", "warning");
            return;
        }

        const formData = new FormData();
        Object.keys(serviceReqData).forEach(key => {
            formData.append(key, serviceReqData[key]);
        });
        countFile.forEach(file => {
            formData.append('files[]', file);
        });
        formData.append('isForThisServiceCallChecked', isForThisServiceCallChecked)
        formData.append('isIacknowledgeAndChecked', isIacknowledgeAndChecked)

        axios.post(`${process.env.REACT_APP_API_URL}/v1/svcrequest`, formData)
            .then(() => {
                swal("", "Work order submitted successfully!", "success");
                // Optional: Reset form after successful submission
                setServiceReqData({
                    PropertyId: '',
                    CategoryId: '',
                    Category: '',
                    SubCategoryId: '',
                    SubCategory: '',
                    RequestSummary: '',
                    UnitId: '',
                    TenantId: '',
                    Tenant: '',
                    Priority: '',
                    PropertyAddress: '',
                    source:''
                });
                setFileCount([]);
                setCaptchaVerified(false);
                setTermsAndConditionsChecked(false);
                setIsTenantExist(null);
            })
            .catch(err => {
                swal("", "There was an error submitting the work order. Please try again.", "error");
                console.error(err);
            });
    };

    const handleKeyPress = (key) => {
        if (key.key === 'Enter') {
            verifyTenantEmail()
        }
    }
    useEffect(() => {
        isForThisServiceCallChecked && setIacknowledgeAndChecked(false)
    }, [isForThisServiceCallChecked])


    return (
        <>
            <KWNavbar />
            <div className="serviceRequest-container mt-5 pt-5">
                <div className="mt-5">
                    <EmailVerificationSection
                        email={email}
                        setEmail={setEmail}
                        handleKeyPress={handleKeyPress}
                        isTenantExist={isTenantExist}
                        isLoading={isLoading}
                        verifyTenantEmail={verifyTenantEmail}
                    />
                    {isTenantExist && (
                        <>
                            <PropertyDetailsSection isTenantExist={isTenantExist} />
                            <ServiceRequestForm
                                serviceCategory={serviceCategory}
                                subCategory={subCategory}
                                serviceReqData={serviceReqData}
                                setServiceReqData={setServiceReqData}
                                setsubCategory={setsubCategory}
                                countFile={countFile}
                                handleDocumentUploads={handleDocumentUploads}
                                removeFile={removeFile}
                                handleDragOver={handleDragOver}
                                handleDrop={handleDrop}
                                isForThisServiceCallChecked={isForThisServiceCallChecked}
                                setisForThisServiceCallChecked={setisForThisServiceCallChecked}
                                isIacknowledgeAndChecked={isIacknowledgeAndChecked}
                                IacknowledgeAndChanged={() => setIacknowledgeAndChecked(!isIacknowledgeAndChecked)}
                            />
                            <TermsAndConditionsSection
                                isTermsAndConditionsChecked={isTermsAndConditionsChecked}
                                setTermsAndConditionsChecked={setTermsAndConditionsChecked}
                            />
                            <div className="mt-2 ps-2">
                                <div className="service-form-label2 mb-2">To protect this website from spam, please check the box below.</div>
                                <div>
                                    <ReCAPTCHA
                                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                        onChange={handleCaptcha}
                                    />
                                </div>
                                <div className="mt-3">
                                    <button
                                        type='button'
                                        className='Property-Currently-button'
                                        onClick={captchaVerified ? submitWorkOrderForm : () => {
                                            swal('!', `Sorry! To protect this website from spam, please check the "I'm not a robot" box below. Please try again.`, 'warning');
                                        }}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <KWFooter />
        </>
    );
};

export default ServiceRequestNew;
