import React, { useEffect, useState } from 'react';
import { Card, Col, Container } from 'react-bootstrap';
import axios from 'axios';
import Moment from 'react-moment';
import moment from 'moment-timezone';
import { LineWave } from 'react-loader-spinner';
import KWAdminNavbar from '../../components/navbar/AdminNav';
import { useNavigate } from 'react-router-dom';
import agents from '../../data/agents.json'
import { verifyToken } from '../../utils/verifyToken';

Moment.globalTimezone = 'America/Toronto';
const getAgentByColorId = (colorId) => agents.filter(a => a.colorId == colorId)

const EventItem = ({ item, navigate,agent }) => (
  <div className="row mt-2">
    <div className="col-sm-12 col-md-2 time">
      <Moment date={item.start.dateTime} format="hh:mm a" />
    </div>
    <div className="col-sm-12 col-md-4">
      <p
        style={{ cursor: 'pointer', marginBottom: '0px' }}
        onClick={() => navigate(`/listing/${item.extendedProperties.shared.propertyId}`)}
        className='middle-link1'
      >
        {item.extendedProperties.shared.propertyTitle}
      </p>
      <small style={{ color: '#999998', marginTop: '-5px' }} className='d-block p-0'>{item.extendedProperties.shared.unitTitle}</small>
      
    </div>
    <div className="col-sm-12 col-md-2">
      <div className='w-100 d-flex align-items-center'>
        <div className='me-2'>Status: </div>
        <div>
          {item.details.meetingStatus === 'c' ? (
            <span className='text-danger'>CANCELLED</span>
          ) : (
            <span className='text-success'>OPEN</span>
          )}
        </div>
      </div>
    </div>
    <div className='col-sm-12 col-md-2'>
      <div className='w-100 d-flex align-items-center'>
        <div className='me-2'><strong>Agent</strong> </div>
        <div style={{ borderBottom: `5px solid ${agent.color_code}` }}>
          {item.extendedProperties?.shared?.agentName || 'N/A'}
        </div>
      </div>
    </div>
    <div className="col-sm-12 col-md-2">
      <button
        onClick={() => navigate(`/admin/update-event/${item.id}`, { state: item })}
        className='book-Showing-c btn btn-warning'
      >
        UPDATE
      </button>
    </div>
  </div>
);

const EventDateGroup = ({ dateKey, items, navigate }) => {
  const timeZone = 'America/Toronto';
  const formattedDate = moment.tz(dateKey, timeZone);
  const isToday = moment().isSame(formattedDate, 'day');
  const isTomorrow = moment().add(1, 'day').isSame(formattedDate, 'day');

  let displayDate;
  if (isToday) {
    displayDate = 'Today';
  } else if (isTomorrow) {
    displayDate = 'Tomorrow';
  } else {
    displayDate = formattedDate.format('MMM DD, YYYY');
  }

  return (
    <div className="secound-content mt-1">
      <div className="content2-head">{displayDate}</div>
      <div className="content2-border"></div>
      <div className="content2-row">
        {items.map((item) => {
          const agent = getAgentByColorId(item?.colorId)[0]
          return item.details && <EventItem key={item.id} agent={agent} item={item} navigate={navigate} />
        })}
      </div>
    </div>
  );
};

const ListCalenderEvent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [itemData, setItemData] = useState({});
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/v1/showing/events`);
      setItemData(res.data.groupedEvents);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(()=> {
    verifyToken(); // verify token on page load
  },[])
  useEffect(() => {
    fetchData();
  }, [navigate]);

  return (
    <>
      <KWAdminNavbar />
      <Container className='pt-5'>
        <div className='form-content'>
          <Col className='mx-auto' sm={12} lg={12}>
            <Card className='pt-3 border-0 minheight form-card mt-4'>
              <h4 className='text-center m-2'>Showings</h4>
              <div className="col-sm-12">
                {isLoading ? (
                  <div className="d-flex justify-content-center">
                    <LineWave
                      height="200px"
                      width="300px"
                      color="#0586f0"
                      ariaLabel="line-wave"
                      visible={true}
                    />
                  </div>
                ) : (
                  Object.keys(itemData).length > 0 ? (
                    Object.keys(itemData).map((key) => (
                      <EventDateGroup key={key} dateKey={key} items={itemData[key]} navigate={navigate} />
                    ))
                  ) : (
                    <div style={{ minHeight: '40vh' }} className="d-flex text-danger align-content-center justify-content-center">
                      No Events Found!
                    </div>
                  )
                )}
              </div>
            </Card>
          </Col>
        </div>
      </Container>
    </>
  );
};

export default ListCalenderEvent;