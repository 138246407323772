export const team = [
    {
      id: 1,
      name: 'Trish Montag',
      designation: 'Owner',
      description: 'Just as she turned 20 years old, Trish had jumped into the industry with both feet and all heart in negotiating her first triplex purchase, with limited experience and an abundance of passion she never looked back. Trish connected with an ever expanding network of savvy professionals and attended every industry related event she could find. With nowhere else to go but up, she launched K-W Property Management Corp and has embraced the many challenges and rewards along the way. Backed by her dedicated team, Trish thrives on working with investors to help locate the right demographics, attract the most reliable tenants and negotiate secured, long term cash flow. Supporting the Tri-City area where her roots are firmly planted, Trish is a perfect example of how hard work pays off. She looks forward to expanding on her team\'s positive affect of meeting new investors and helping to take the worry out of their personal and financial futures.',
      img: './team/trish.jpg'
    },
    {
      id: 2,
      name: 'Debbie Montag',
      designation: 'Administration',
      description: 'While Trish is certainly the founder of K-W Property Management Corp., one could say that it was Debbie who truly started it all. As Trish\'s mother, Debbie has been there since day one and brings over 25 years of experience in sales and customer service to the table. Although well versed in every aspect of the business, Debbie is most in her element when finding tenants a home to plant their roots in. She loves the challenges that the real estate industry brings each day and is clearly at her best when getting to know clients’ needs and helping them find exactly what they\'re looking for. It all started with helping her daughter in a small firm and now she can\'t wait to see which new direction the business goes in next. Of course, no matter where it ends up, there\'s never a dull moment; only new opportunities to help good people find great homes which is just the way she likes it.',
      img: './team/debbie.jpg'
    },
    {
      id: 4,
      name: 'Nada Cupic',
      designation: 'Maintenance Department',
      description: 'Nada is deeply passionate about two things: real estate and people. She is well-versed in the rental market, housing market, and legal industry. Licensed by the Law Society of Ontario since 2019, Nada is ready to ensure both Landlords and Tenants alike understand their rights and responsibilities. As K-W Property Management Corp. Maintenance Department and Property Manager, she is on a mission to present rental properties, organize property maintenance and prepare legal documentation, so her valued clients can avoid any potential headaches. She may be one of the youngest professionals in the industry, but that doesn\'t mean she\'s not armed with the wealth of knowledge, proven expertise, and people skills necessary to offer the most seamless experience possible for all local real estate tenants and landlords alike. When she isn\'t assisting clients, Nada enjoys spending time outdoors by the water.',
      img: './team/nada.jpg'
    },
    {
      id: 5,
      name: 'Flora Mehboob',
      designation: 'Office Manager',
      description: 'Flora is no stranger to relentless work ethic and strategic leadership. Overseeing daily operations, helping the team, improving customer satisfaction, and building strong relationships with tenants and clients are only part of her duties. With a focus on efficiency, organization, sales, Flora is armed with a highly detail-oriented and hardworking mindset and exceptional people skills that make her a true asset to our thriving team of experts. Outside of the office, Flora can be found relaxing at home with her beautiful family, including her loving husband, daughter, and son.',
      img: './team/flora.jpg'
    },
    {
      id: 6,
      name: 'Noah Koop',
      designation: 'Leasing Agent',
      description: 'A recent graduate & recipient of a BBA from Conestoga College\'s International Business Management program, Noah may be young, but his passion and fire for real estate more than make up for his addition to the team at such a young age. In various roles within the organization, he\'s a quick learner and is always looking to ensure there are better, more efficient ways to run things. His schooling has definitely given him the top-down view required for success in this business. Even when he\'s not working, he\'s always learning more about real estate and the business world in general. He loves to spend his free time with his girlfriend and their dog. He\'s also been hit with the travel bug and tries to hopscotch around the world as much as he can, just recently returning from a whirlwind tour of Italy.',
      img: './team/noah.jpeg'
    },
    {
      id: 7,
      name: 'Cole Wilton',
      designation: 'AR/Collections Specialist',
      description: 'Cole just recently graduated and returned home from completing his BBA Degree at the Institute of Technology, Carlow in Ireland. Although he may be new to the company, he is passionate and has been immersed in the Real Estate business for several years. Cole takes pride in the work he does, and ensures that all tenants and landlords receive industry leading service, every time. In his off-time, he loves hockey, golf and fishing. Those analogous endeavors dovetail right into his work as he\'s the first to call an off-side as he sees it, drive the whole distance with a problem and he never knows what he\'ll catch everyday when he throws out his first line of the day into the waters of the rental world.',
      img: './team/cole.jpg'
    },
    {
      id: 8,
      name: 'Kristi Young',
      designation: 'Intake Specialist',
      description: 'Kristi comes to us from an unconventional background but her skillset translates well to her new duties on our team as part of our leasing department. Prior to landing here, she was a school teacher with a focus on child youth development. A 2016 graduate of Trenton University and hailing from the small northern town of Picton, Ontario, she\'s excited to be in the big city and working in the fast-paced industry of real estate. She\'s just recently married and loves spending time with her husband and their fur-baby Oakley, a gentle German Shepherd who occasionally comes by to say hello to the team.',
      img: './team/kristi.jpg'
    },
    {
      id: 11,
      name: 'Skylar Escott',
      designation: 'Reception',
      description: 'Skylar joined us in an administrative role and has developed into much more since beginning. With a background in Events Management and Hospitality, she\'s perfectly suited for her front office reception role. She\'s the team member you first meet when visiting our office. She\'ll always greet you with a smile and assist you in the best way possible. In her free time, she\'s a hardcore foodie and loves traveling, listening to music and getting out on the social scene as much as she can.',
      img: './team/skylar.jpg'
    }
  ]