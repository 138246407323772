import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import fourColumnImage from '../../assets/images/FourColumn.png';
import threeColumnImage from '../../assets/images/ThreeColumn.png';
import './banner.css';

export const KWBanner = () => {
  const [imageSrc, setImageSrc] = useState(fourColumnImage);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setImageSrc(threeColumnImage);
      } else {
        setImageSrc(fourColumnImage);
      }
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="hero-container">
      <Container className='mt-5'>
        <Row style={{ marginTop: 0, marginBottom: 0 }}>
          <Col sm={12} lg={6} className='d-flex flex-column align-items-start justify-content-center hero-item animate__animated animate__backInDown' style={{ marginTop: '20px' }}>
            <h1 className='text-start'>K-W PROPERTY MANAGEMENT CORP.</h1>
            <h2 className='blue'>Helping you find your home.</h2>
        
          </Col>
          <Col sm={12} lg={6} className='d-flex align-items-center hero-item'>
            <img src={imageSrc} style={{ height: '385px', margin: 'auto' }} alt="Hero" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default KWBanner;