import './App.css';
import { Home } from './pages/Home/Home';
import { Browse } from './pages/Browse/Browse';
import { Route, Routes } from 'react-router-dom';
// import { Properties } from './pages/Properties/properties';
import { Contactus } from './pages/Contactus/contactus';
import { Ebook } from './pages/Ebook/Ebook';
import { Login } from './pages/Login/login';
import { Team } from './pages/Team/team';
// import { GeneralApllication } from './pages/GeneralAplication/GeneralApllication';
// import { StudentApplication } from './pages/StudentApplication/StudentApplication';
import { Showing } from './pages/Showing/Showing';
import Listing from './pages/Listing/listing';
// import { Tenent } from './pages/tenent/Tenent';
import { Landlords } from './pages/Landlords/Landlords';
import  ServiceRequest  from './pages/serviceRequest/ServiceRequest';
import { ConfirmProperty } from './pages/confirmProperty/ConfirmProperty';
import { TenentHandbook } from './pages/TenentHandbook/TenentHandbook';
import { BecomeATenent } from './pages/BecomeATenent/BecomeATenent';
import { SubletAgreement } from './pages/SubletAgreement/SubletAgreement';
import { N9 } from './pages/N9/N9';
import { ScheduleShowing } from './pages/ScheduleShowing/ScheduleShowing';
import PropertyView from './pages/SinglePropertyView/PropertyView';
import Thankyou from './pages/Thankyou/Thankyou';
import ThankYou from './pages/Thank-You/ThankYou';
import AddEvent from './pages/CalenderEvents/AddEvent';
import Admin from './pages/Admin/Index';
import ListCalenderEvent from './pages/CalenderEvents/ListCalenderEvent';
import UpdateEvent from './pages/CalenderEvents/UpdateEvent';
import LoginForm from './pages/Admin/Login';
import AdminHome from './pages/Admin/AdminHome';
import Title from './pages/Admin/Title';
import Faq from './pages/FAQ/Faq';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="browse" element={<Browse />} />
        {/* <Route path="properties" element={<Properties />} /> */}
        {/* 
        
        <Route path="properties" element={<Properties />} /> */}
        <Route path="contactus" element={<Contactus />} />
        <Route path="ebook" element={<Ebook />} />
        <Route path="ebook/thankyou" element={<Thankyou />} />
        <Route path="login" element={<Login />} />
        <Route path="team" element={<Team />} />
        <Route path="listing" element={<Listing />} />
        <Route path="listing/:id" element={<PropertyView />} />
        {/* <Route path="genaral-application" element={<GeneralApllication />} />
        <Route path="student-application" element={<StudentApplication />} /> */}
        <Route path="Showing" element={<Showing />} />
        {/* <Route path="Tenent" element={<Tenent />} /> */}
        <Route path="Landlords" element={<Landlords />} />
        <Route path="ServiceRequest" element={<ServiceRequest />} />
        <Route path="ConfirmProperty/:id" element={<ConfirmProperty />} />
        <Route path="TenentHandbook" element={<TenentHandbook />} />
        <Route path="BecomeATenent" element={<BecomeATenent />} />
       
        <Route path="SubletAgreement" element={<SubletAgreement />} />
        <Route path="TenentTenancy" element={<N9 />} />
        <Route path="ScheduleShowing" element={<ScheduleShowing />} />
        <Route path='thankyou' element={<ThankYou />} />

        <Route path='/FAQ' element={<Faq/>}/>

        <Route path='/admin' element={<Admin />} >
          <Route path='/admin' element={<LoginForm />} />
          <Route path='/admin/add-title' element={<Title />} />
          <Route path='/admin/dashboard' element={<AdminHome />} />
          <Route path='/admin/add-event' element={<AddEvent />} />
          <Route path='/admin/event-list' element={<ListCalenderEvent />} />
          <Route path='/admin/update-event/:id' element={<UpdateEvent />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
