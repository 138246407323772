export const verifyToken = async () => {
    const token = sessionStorage.getItem('token');
    if (!token) {
        window.location.href = '/admin';
    } else {
        const tokenVerifyResponse = await fetch(`${process.env.REACT_APP_API_URL}/v1/login/verify`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${token}`
                }
            }
        )
        if (!tokenVerifyResponse.ok) {
            sessionStorage.removeItem('token');
            window.location.href = '/admin';
        }
    }

}