import React, { useEffect, useState } from 'react';
import KWNavbar from "../../components/navbar/navbar";
import KWFooter from "../../components/footer/footer";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import Modal from "react-bootstrap/Modal";
import Carousel from 'react-bootstrap/Carousel';


import samplePic from '../../assets/no-image.png';
import camera from '../../assets/icons8-compact-camera-50.png';
import { FaBath, FaBed, FaCheck } from "react-icons/fa";
import { LineWave } from 'react-loader-spinner';
import { LiaVectorSquareSolid } from "react-icons/lia";
import './PropertyView.css';

export default function PropertyView() {
    const UnitBedrooms = {
        "OneBed": 1,
        "TwoBed": 2,
        "ThreeBed": 3,
        "FourBed": 4,
        "FiveBed": 5,
        "SixBed": 6,
        "SevenBed": 7,
        "EightBed": 8,
        "NineBed": 9,
        "TenBed": 10
    };

    const UnitBathrooms = {
        "OneBath": 1,
        "OnePointFiveBath": 1.5,
        "TwoBath": 2,
        "TwoPointFiveBath": 2.5,
        "ThreeBath": 3,
        "ThreePointFiveBath": 3.5,
        "FourBath": 4,
        "FourPointFiveBath": 4.5,
        "FiveBath": 5
    };

    const [modalShow, setModalShow] = useState(false);
    const [listing, setListing] = useState();
    const [bedroomsValue, setBedroomsValue] = useState('N/A');
    const [bathroomsValue, setBathroomsValue] = useState('N/A');
    const location = useLocation();
    const id = location.pathname.split('/')[2];
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [unitDescription, setUnitDescription] = useState();

    useEffect(() => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}/v1/listing/kwpweb/unit/${id}`)
            .then(async (resp) => (resp.ok ? await resp.json() : Promise.reject()))
            .then((data) => {
                setLoading(false);
                setListing(data);
                getUnitDescription(data);
                setBedroomsValue(UnitBedrooms[data?.Unit?.UnitBedrooms] || "N/A");
                setBathroomsValue(UnitBathrooms[data?.Unit?.UnitBathrooms] || "N/A");
            })
            .catch(() => setLoading(false));
    }, [id]);

    const handleApplyOnline = () => {
        window.open(`https://app.verifast.com/appv1/3000/KW-Property-Application/login`);
        // window.open(`https://manage.mipropertyportal.com/App/RentalApplication/Index?propertyId=${listing.Unit.propertyId.replace('P', '')}&unitTypeId=${listing.Unit.Id.replace('T', '')}`)
    };

    const handleNavigate = () => {
        navigate(`/ScheduleShowing?uid=${listing.Unit.Id}`);
    };

    const renderDescription = (description) => {
        const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
        const descriptionWithLinks = description.replace(emailRegex, (match) => `<a href="mailto:${match}">${match}</a>`);
        const formattedDescription = descriptionWithLinks.replace(/\n/g, '<br/>');
        return <h5 dangerouslySetInnerHTML={{ __html: formattedDescription }} className="text-align-adjust" />;
    };

    const formatNumber = (number) => number.toLocaleString('en-US');
    function getUnitDescription(listing) {
        if (listing?.Unit?.unitDescription) {
            try {
                const description = JSON.parse(listing.Unit.unitDescription);
                setUnitDescription(description);
            } catch (error) {
                setUnitDescription([]);
            }
        }
    }
    return (
        <div>
            <KWNavbar />
            <Container className="p-5">

                {listing ? (
                    <>
                        <Row className="pt-5 mt-5">
                            <Col sm={12} lg={6}>
                                <h2 className="text-align-adjust">{listing?.Property.Name}</h2>
                                <p className= "text-align-adjust">{listing?.Property.PropertyUnitTypeTitle}</p>
                                <h5 className="fw-normal mt-4 text-left">
                                    <b className='text-capitalize'>
                                        {listing?.Unit?.Address?.AddressLine1 &&
                                            `${listing.Unit.Address.AddressLine1.split(',')
                                                .filter((v, i, a) => (i !== (a.length - 1) && i !== (a.length - 2)))
                                                .join(' ,').toLowerCase()}.`}
                                    </b>
                                </h5>
                                <h5 className='text-capitalize'>
                                    <b>
                                        {listing?.Unit?.Address?.AddressLine1 &&
                                            `${listing.Unit.Address.AddressLine1.split(',')
                                                .filter((v, i, a) => (i === (a.length - 2)))
                                                .join(' ,').toLowerCase()},`}
                                        {`${listing?.Unit?.Address?.PostalCode}`}
                                    </b>
                                </h5>
                                <div >
                                    <h5 className="text-align-adjust">
                                    {listing?.Unit?.Description && renderDescription(listing.Unit.Description)}
                                    </h5>
                                    
                                    
                                    <ul className="text-align-adjust">
                                        {unitDescription?.map((item, index) => (
                                            <li className='fw-semibold' key={index}>{item}</li>
                                        ))}
                                    </ul>
                                    <h5 className="text-align-adjust">
                                        To book an appointment check our online calendar for upcoming
                                        showings <a href={`${process.env.REACT_APP_BASE_URL}/Showing`}>here</a>. If you do not find a suitable showing time, please email us your availability and we will book you a time to see the unit.
                                    </h5>
                                </div>
                                <h3 className="blue mt-5">${formatNumber(listing?.Rent)}/month</h3>
                                <Button onClick={handleApplyOnline} className="btn btn-primary d-block mt-4 button-c">Apply Online Today</Button>
                                <Button onClick={handleNavigate} className="btn btn-primary d-block mt-4 button-c">Book Appointment</Button>
                            </Col>

                            <Col sm={12} lg={6} className="ps-lg-5 mt-5 mt-lg-0 position-relative">
                                <Image
                                    src={listing?.Unit.defaultImg || samplePic}
                                    className="image-container mb-4 mb-lg-0"
                                    fluid
                                />
                                <button onClick={() => setModalShow(true)} className="seePhotoBtn">
                                    <img className="p-1" src={camera} alt="See Photos" width={30} />
                                    See all photos
                                </button>
                                <div className='w-100 Pdetails py-4 d-flex justify-content-around'>
                                    <div>
                                        <FaBed title='Bedrooms' size={20}  className="icon-primary"/>
                                        <span>{" " + listing?.Unit?.UnitBedrooms || 'N/A'}</span>
                                    </div>
                                    <div className='d-flex gap-2 align-items-center'>
                                        <FaBath title='Bathrooms' size={20}  className="icon-primary" />
                                        <span>{" " + listing?.Unit?.UnitBathrooms || 'N/A'}</span>
                                    </div>
                                    <div>
                                        <LiaVectorSquareSolid title='Sq Ft' size={20}  className="icon-primary"/>
                                        <span>{" " + (listing?.Unit?.UnitSize !== 0 ? listing?.Unit?.UnitSize : 'N/A')}</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        {listing?.Unit?.Features?.length > 0 && (
                            <>
                                <br /><br />
                                <div className="content-row row">
                                    <div className="col-12">
                                        <div className="panel features-panel">
                                            <div className="panel-title">Features</div>
                                            <ul className="feature-list fa-ul no-bullets">
                                                {listing?.Unit?.Features.map((feature, index) => (
                                                    <li key={index} className='Features-li'>
                                                        <FaCheck className='text-success mx-2' />
                                                        {feature}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {listing?.Unit?.Files?.length > 0 && (
                            <>
                                <br /><br />
                                <div className="content-row row">
                                    <div className="col-12">
                                        <div className="panel features-panel">
                                            <div className="panel-title">More Photos</div>
                                            <div className='MorePhotos'>
                                                {listing?.Unit?.Files.map((file, index) => (
                                                    <div key={index}>
                                                        <img
                                                            onClick={() => setModalShow(true)}
                                                            src={file.Url}
                                                            alt="More Photos"
                                                            className='MorePhotos-Img'
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <div className="d-flex justify-content-center">
                        {loading && <LineWave height="200px" width="300px" color="#0586f0" ariaLabel="line-wave" visible={true} />}
                    </div>
                )}
            </Container>

            <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton />
                <Modal.Body>
                    <div className="center h-50">
                        <Carousel>
                            {listing?.Unit.Files?.map((img, index) => (
                                <Carousel.Item key={index}>
                                    <Image src={img.Url} className="image-containers mb-4 mb-lg-0" fluid />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                </Modal.Body>
            </Modal>
            <KWFooter />
        </div>
    );
}
