import React, { useEffect, useState } from 'react'
import KWAdminNavbar from '../../components/navbar/AdminNav'
import "./style.css"
import { Col, Container, Row } from 'react-bootstrap'
import { verifyToken } from '../../utils/verifyToken';

const AdminHome = () => {
    const [totalListings, setTotalListings] = useState(0)
    const [totalProperties, setTotalProperties] = useState(0)
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch total listings
                const listingsResponse = await fetch(`${process.env.REACT_APP_API_URL}/v1/listing/kwpweb`);
                const listingsData = await listingsResponse.json();
                setTotalListings(listingsData.length || 0);

                // Fetch total properties
                const propertiesResponse = await fetch(`${process.env.REACT_APP_API_URL}/v1/property`);
                const propertiesData = await propertiesResponse.json();
                setTotalProperties(propertiesData.data?.length || 0);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        verifyToken(); // verify token on page load
        fetchData();
    }, [])
    return (
        <>
            <KWAdminNavbar />
            <div className='dash-board'>


                <Container>
                    <div className='my-2 fs-3'>Dashboard</div>
                    <Row className=" gap-3">
                        <Col className='rounded' xs={12} sm={6} md={4} lg={3}
                            style={{
                                background: '#EEEEEE',
                                height: '100px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '10px'
                            }}
                        >
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '10px'
                                    }}
                                >
                                    <div className='fs-5'>Managed Properties</div>
                                </div>
                                <div className='fs-4 fw-bold text-center'>{totalProperties}</div>
                            </div>

                        </Col>
                        <Col className='rounded' xs={12} sm={6} md={4} lg={3}
                            style={{
                                background: '#EEEEEE',
                                height: '100px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '10px'
                            }}
                        >
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '10px'
                                    }}
                                >
                                    <div className='fs-5'>Current Listing</div>
                                </div>
                                <div className='fs-4 fw-bold text-center'>{totalListings}</div>
                            </div>

                        </Col>
                    </Row>
                </Container>

            </div>
        </>
    )
}

export default AdminHome
